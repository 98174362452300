import React from "react";
import {
  BrowserRouter ,
  HashRouter,
  Switch
} from "react-router-dom";

import Login from './Login/Login';
import Home from './Home/Home';
import CourseEachSubject from './Course/CourseEachSubject';
import Course from './Course/Course';
import Log from './Log/LogList';

import User from './User/User';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

export class Routes extends React.Component {
  render() {
    return (
      <HashRouter basename={process.env.BASE_URL}>
        <Switch>
          <PrivateRoute 
            exact
            path="/"
            component={Home}
          >
          </PrivateRoute>
          <PrivateRoute 
            exact 
            path="/course"
            component={Course}
          >
            <Course />
          </PrivateRoute>
          <PrivateRoute
            exact 
            path="/course/editar/:id"
            component={CourseEachSubject}
          >
            <CourseEachSubject />
          </PrivateRoute>
          <PrivateRoute
            exact 
            path="/user/create_user/"
            component={User}
          >
            <User />
          </PrivateRoute>
          <PrivateRoute
            exact
            path="/log"
            component={Log}
          >
            <Log />
          </PrivateRoute>
          <PublicRoute exact path="/login" component={Login}>
            <Login />
          </PublicRoute>
        </Switch>
      </HashRouter>
  );
  }
}

export default Routes;
