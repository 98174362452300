import React, { Component } from 'react';
import axios from 'axios';
import { Table } from 'react-materialize';
import { Api, Header} from "./../util/Api";
import { 
  Button,
  Pagination,
  Icon,
  Modal,
  Collapsible,
  CollapsibleItem,
  Row,
  Col,
  Tab,
  Tabs,
  CardPanel,
  MediaBox
} from 'react-materialize';
import M from 'materialize-css';
import './Course.css';
import Loader from '../util/Loader';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Dashboard } from '../Dashboard/Dashboard';

export default class Course extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      modalOpen: false,
      courses: [],
      course: [],
      courseId: 0,
      subjects: [],
      paginate: [],
      activePage: 1,
      countPaginate: 0
    };
  }
  componentDidMount(){
    M.AutoInit();
    M.Modal.init()
    M.Tabs.init()
    this.getCouseAll()
  }
  
 
  getCouseAll(){
    axios.get(Api()+ `/admin/courses/courses/all/ ${this.state.activePage}`, { headers: Header() })
    .then(response => {
      this.setState({
        courses: response.data.results,
        countPaginate:  Math.ceil(response.data.count / 10)
      });
    })
    .catch(error => {
      console.log(error);
    })
    
  }

  async getSubject(id){
    await axios.get(Api()+ `/admin/courses/course/${id}`, { headers: Header() })
    .then(response => {
      this.setState({
        subjects: response.data.subjects
      });
    })
    .catch(error => {
      console.log(error);
    })
  }

  getSubjectEachCourse(){
    return (<Collapsible 
      accordion
      popout
        >
          {this.state.subjects.map((item) => (
            <CollapsibleItem
              key={item.pk}
              expanded={false}
              header={item.fields.title}
              icon={<Icon>book</Icon>}
              node="div"
            >
              <Row>
                <Col m={12} s={12}>
                  <h6>{item.fields.title} 
                    <Button 
                      node="a"
                      to='/'
                      waves="light"
                      className="right">
                        Editar
                    </Button>
                  </h6>
                  <MediaBox
                    id="MediaBox_7"
                    options={{
                      inDuration: 275,
                      onCloseEnd: null,
                      onCloseStart: null,
                      onOpenEnd: null,
                      onOpenStart: null,
                      outDuration: 200
                    }}
                  >
                    <img
                      alt=""
                      src={item.fields.image}
                      width="300"
                    />
                  </MediaBox>
                  <p>{item.fields.syllabus}</p>
                
                </Col>
              </Row>
              
            </CollapsibleItem>
          ))}
        </Collapsible>

    )  
    
  }

  getModalListCourse(){
    return(
      <div>
        <h6>Curso: {this.state.course.title} 
    {/* <p>{this.state.course}</p> */}
          <Link 
            to={{
              pathname:`/course/editar/${this.state.courseId}`,
              state: {course: this.state.course}
            }}
            className="button right waves-effect waves-light btn"
          >
            Editar
          </Link>
        </h6>
        <Table>
          <thead>
              <tr>
                <th data-field="code">
                  Código
                </th>
                <th data-field="description">
                  Descrição
                </th>
                <th data-field="video">
                  Video
                </th>
                <th data-field="author">
                  Author
                </th>
                <th data-field="field">
                  Campo
                </th>
                <th data-field="price">
                  Preço
                </th>
                <th data-field="students">
                  Estudantes
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{this.state.course.code}</td>
                <td>{this.state.course.description}</td>
                <td>{this.state.course.video}</td>
                <td>{this.state.course.author}</td>
                <td>{this.state.course.field}</td>
                <td>R$ {this.state.course.price}</td>
                <td>{this.state.course.students}</td>
              </tr>
            </tbody>
        </Table>
      </div>
    );
  }
  modalSubject(){
    return (
      <Modal
        actions={[
          <Button flat modal="close" node="button" waves="green"
          onClick={() => this.setState({modalOpen: false})
          }>
            <Icon>close</Icon>
          </Button>
        ]}
        bottomSheet={false}
        fixedFooter={true}
        header=""
        className="modal"
        open={this.state.modalOpen}
        options={{
          endingTop: '10%',
          inDuration: 250,
          onCloseEnd:  () => { this.setState({modalOpen: false})} ,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.9,
          outDuration: 250,
          startingTop: '4%'
        }}
      >
      {this.contentModalSubject()}
      </Modal>
    );
  }

  contentModalSubject(){
    return (
      <div>
        <Tabs 
          className="z-depth-1 disable"
          options={{
            swipeable: false,
            responsiveThreshold: Infinity
          }}
          >
          <Tab
            options={{
              swipeable: false
            }}
            title="Curso"
          >
            {this.getModalListCourse()}
          </Tab>
          <Tab 
            options={{
              active: true,
              swipeable: false
            }}
           
            title="Disciplinas"
          >
            {this.getSubjectEachCourse()}
          </Tab>
        </Tabs>
      </div>

    );
  }
  openSubject(id){
    this.getSubject(id)
    this.setState({modalOpen: true});
  }

  listCourses(){
     const listItems = this.state.courses.map((item) =>
      <tr key={item.pk} >
        <td>{item.fields.title}</td>
        <td>{item.fields.code}</td>
        <td>R$ {item.fields.price}</td>
        <td>
           <Button
              node="a"
              small
              waves="light"
              href="#Modal-0"
              onClick={() => {this.openSubject(item.pk); this.setState({course:  item.fields, courseId: item.pk});}}
            >
              Detalhar
            </Button>
        </td>
        <td>
          
        </td>
      </tr>
    );  
    return listItems
  }
  
 
  tableCourse(){
    return (
      <div className="course">
        <Dashboard>

          <CardPanel>

          <Table>
            <thead>
              <tr>
                <th data-field="title">
                  Título
                </th>
                <th data-field="code">
                  Código
                </th>
                
                <th data-field="price">
                  Preço
                </th>
                <th data-field="detail">
                  Detalhar
                </th>
                
              </tr>
            </thead>
            <tbody>
            {this.listCourses()}
            </tbody>
          </Table>

          <Row>
            <Col m={12}>
              <Pagination
                className="center"
                activePage={this.state.activePage}
                items={this.state.countPaginate}
                maxButtons={8}
                leftBtn={<Icon>chevron_left</Icon>}
                rightBtn={<Icon>chevron_right</Icon>}
                onSelect={ async (e)=>  {
                  await this.setState({activePage: e});
                  console.log(e)
                  this.getCouseAll(); 
                  this.setState({loading: true});
                }}
              />
            </Col>
          </Row>
          
            {this.modalSubject()}
          </CardPanel>
        </Dashboard>

      </div>
    )
  }
  loading(){
    return  (
      <Loader key={this.state.course}/>
    )
  }
  loadingFalse(){
    return setTimeout(() => {
      this.setState({loading: false});
    }, 2000);
  }
  render() {
   if (this.state.loading === true) {
     return [ this.loading(),this.loadingFalse()]
   } else {
     return this.tableCourse()
   }
  }
}
