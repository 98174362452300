import React, { Component } from 'react';
import axios from 'axios';
import {
  Divider,
  Icon,
  Collapsible,
  CollapsibleItem,
  Button,
  Modal,
  Row,
  Tab,
  Tabs,
  Col,
  CardPanel
} from 'react-materialize';
import { Table } from 'react-materialize';
import Moment from 'react-moment';
import 'moment/locale/pt-br';
import './UserSearch.css';
import { Api, Header} from "./../util/Api";

export default class UserSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalSearchOpen: false,
      allCouseUser: [],
      modalDeleteAssociation: false,
      statusChangePassword: '',
      statusChangePasswordColor: '',
      user: [],
      course: [],
      username: ''
    };
    this.handlerChange = this.handlerChange.bind(this);
  }

  handlerChange = (event) => {
    let name = event.target.name;
    let val = event.target.value;
    this.setState({[name]: val});
  }

  
  getEachUser(){
    axios.get(Api()+ `/admin/users/users/search/${this.state.username}`, { headers: Header() })
    .then(response => {
      console.log(response.data);
      this.setState({
        user: response.data.results,
      });
    })
    .catch(error => {
      console.log(error);
    })
  }
  getCourseUserEmail(){
    axios.get(Api()+ `/admin/course_user_with_email/${this.state.username}`, { headers: Header() })
    .then(response => {
      this.setState({
        course: response.data.course
      });
    })
    .catch(error => {
      console.log(error);
    })
  }

  handleChangePassword(){
    axios.post(Api()+ `/admin/reset_password`, {
      username: this.state.username,
      password: this.state.password
    },
    { headers: Header() })
    .then(response => {
      console.log(response);
      this.setState({
        password: '',
        statusChangePassword: 'Mudado senha',
        statusChangePasswordColor: 'green-text'
      });
    })
    .catch(error => {
      console.log(error.request.status);
      this.setState({
        statusChangePassword: 'Erro em mudar senha',
        statusChangePasswordColor: 'red-text'
      });
    })
  }
  handlerChange = (event) => {
    let name = event.target.name;
    let val = event.target.value;
    this.setState({[name]: val});
  }
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      password: event.target.elements.password.value,
      statusUser: ''
    });
    this.handleChangePassword();
    event.target.reset();
  }
  eachUser(){
    return (
      <div>
        <h5>Busca por: {this.state.username}</h5>
        <Divider />
        <Collapsible accordion>
          {this.resultsUser()}
        </Collapsible>
      </div>
    );
  }
  resultsUser(){
    const listItems = this.state.user.map((item, index) =>
      <CollapsibleItem
        key={index}
        expanded={false}
        header={item.fields.email}
        icon={<Icon>user</Icon>}
        node="div"
      >
        <Tabs className="tab-demo z-depth-1" id={item.pk}>
          <Tab
            active
            options={{
              duration: 300,
              onShow: null,
              responsiveThreshold: Infinity,
              swipeable: false
            }}
            title="Dados do usuário"
          >
            <Table>
              <thead>
                <tr>
                  <th data-field="id">
                    ID
                  </th>
                  <th data-field="username">
                    Username
                  </th>
                  <th data-field="email">
                    Email
                  </th>
                  <th data-field="data">
                    Data
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{item.pk}</td>
                  <td>{item.fields.email}</td>
                  <td>{item.fields.username}</td>
                  <td>
                    <Moment format="LLL" locale="pt-br">
                      {item.fields.date_joined}
                    </Moment>
                  </td>
                </tr> 
              </tbody>
            </Table>
          </Tab>
          <Tab
            options={{
              duration: 300,
              onShow: null,
              responsiveThreshold: Infinity,
              swipeable: false
            }}
            title="Curso do usuário"
          >
            <Table>
              <thead>
                <tr>
                  <th data-field="code">
                  Código
                  </th>
                  <th data-field="title">
                  Título
                  </th>
                  <th data-field="action">
                    Desvicular
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.listUserEachCourse(item.pk)}
              </tbody>
            </Table>
          </Tab>
        
          <Tab
            options={{
              duration: 300,
              onShow: null,
              responsiveThreshold: Infinity,
              swipeable: false
            }}
            title="Mudar senha"
            >
              <Row>
                <form onSubmit={this.handleSubmit}>
                  <Col m={12} s={12}>
                    <input 
                      type="text" 
                      name="password"
                      placeholder="Mudar a senha"
                      id="password"
                      // value={this.state.password}
                      onChange={this.handlerChange}
                    />
                </Col>
                {this.state.statusChangePassword !== '' ? <p className={this.state.statusChangePasswordColor}>{this.state.statusChangePassword}</p>: null }
                <Col m={12} s={12}>
                  <Button node="button" type="submit" waves="light">
                    Submit
                    <Icon right>
                      send
                    </Icon>
                  </Button>
                </Col>
              </form>
            </Row>
          </Tab>
        </Tabs>
      </CollapsibleItem>

    );
    return listItems;
  }

  listUserEachCourse(user_id){
    const listItemsCourse = this.state.course.map((item) => 
    <tr key={item.pk}>
      <td>{item.pk}</td>
      <td>{item.fields.title}</td>
      <td>
        <Modal
          actions={[
            
          ]}
          bottomSheet={false}
          fixedFooter={false}
          header="Desvincular curso para usuário"
          open={this.state.modalDeleteAssociation}
          className="UserListModal "
          options={{
            dismissible: true,
            endingTop: '10%',
            inDuration: 250,
            onCloseEnd: null,
            onCloseStart: null,
            onOpenEnd: null,
            onOpenStart: null,
            opacity: 0.5,
            outDuration: 250,
            preventScrolling: true,
            startingTop: '4%'
          }}
            trigger={<Button node="button">Desvincular</Button>}
          >
            <Button node="button" node="button" waves="green" className="green left" 
            onClick={() => this.openModalListCourseEachUser(user_id, item.pk)}>Desvincular</Button>
            <Button modal="close" node="button" waves="red" className="red white-color right">
              Fechar
            </Button>
          </Modal>
        </td>
      </tr>
    );
    return listItemsCourse;
  }
  openModalListCourseEachUser(user_id, course_id){
    this.deleteCourseUserAssociation(user_id, course_id);
    this.setState({modalDeleteAssociation: true});
  }
  deleteCourseUserAssociation(user_id, course_id){
    const data = {
      user_id: user_id,
      course_id: course_id
    }
    axios.delete(Api()+ `/admin/delete_course_user_association/${user_id}/${course_id}`, 
    { headers: Header(), data: data })
    .then(response => {
      console.log(response);
      this.setState({
        modalDeleteAssociation: false
      })
      this.getCourseUserEmail();
    })
    .catch(error => {
      console.log(error);
    })
  }
  modalSearch(){
    return (
      <Modal
        actions={[
          <div>
            <Button flat modal="close" node="button" waves="green" className="right"
              onClick={() => this.setState({modalSearchOpen: false})}>
                <Icon>close</Icon>
            </Button>
          </div>
        ]}
        bottomSheet={false}
        fixedFooter={false}
        open={this.state.modalSearchOpen}
        id="Modal-User"
        options={{
          dismissible: true,
          endingTop: '20%',
          inDuration: 250,
          onCloseEnd:  () => { this.setState({modalSearchOpen: false})} ,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: '4%'
        }}
        >
        {this.eachUser()}
      </Modal>
    );
  }

  openModalSearch(){
    this.getEachUser();
    this.getCourseUserEmail()
    this.setState({modalSearchOpen: true});
  }

  render() {
    return (
      <CardPanel>
        <Row>
          <Col m={10}>
            <input type="email" name="username" placeholder="Buscar um usuário" id="username" onChange={this.handlerChange}/>
          </Col>
          <Col m={2}>
            
            <Button node="button" waves="green" className="right"
              onClick={() => this.openModalSearch()}>
              <Icon>search</Icon>
            </Button>
          </Col>

        </Row>
        {this.modalSearch()}
      </CardPanel>
    )
  }
}
