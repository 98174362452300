import React, { Component } from 'react'
import {
  Modal,
  Icon,
  Button,
  Pagination,
  Row,
  Col,
  CardPanel
} from 'react-materialize';
import  AssociateCourseToUser from './AssociateCourseToUser';
import  UserCreate from './UserCreate';
import  UserSearch from './UserSearch';

import  UserList from './UserList';
import { Dashboard } from '../Dashboard/Dashboard';

import './User.css';

export default class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalCreateUserOpen: false,
      modalAssociateCourseToUser: false,
      activePage: ''
    };
  }

  modalCreateUser(){
    return (
      <Modal
      actions={[
        <div>
          <Button flat modal="close" node="button" waves="green" className="right"
            onClick={() => this.setState({modalCreateUserOpen: false})}>
              <Icon>close</Icon>
          </Button>
        </div>
      ]}
      bottomSheet={false}
      fixedFooter={false}
      open={this.state.modalCreateUserOpen}
      id="Modal-User"
      options={{
        dismissible: true,
        endingTop: '20%',
        inDuration: 250,
        onCloseEnd:  () => { this.setState({modalCreateUserOpen: false})} ,
        opacity: 0.5,
        outDuration: 250,
        preventScrolling: true,
        startingTop: '4%'
      }}
      >
        <UserCreate />
      </Modal>
    )
  }
  
  openModalCreateUser(){
    this.setState({modalCreateUserOpen: true, modalAssociateCourseToUser: false})
  }

  modalAssociateCourseToUser(){
    return (
      <Modal
      actions={[
        <div>
          <Button flat  modal="close" node="button" waves="green" className="right"
            onClick={() => this.setState({modalAssociateCourseToUser: false})}>
              <Icon>close</Icon>
          </Button>
        </div>
      ]}
      bottomSheet={false}
      fixedFooter={false}
      id="Modal-User"
      open={this.state.modalAssociateCourseToUser}
      options={{
        dismissible: true,
        endingTop: '20%',
        inDuration: 250,
        onCloseEnd:  () => { this.setState({modalAssociateCourseToUser: false})} ,
        opacity: 0.5,
        outDuration: 250,
        preventScrolling: true,
        startingTop: '4%'
      }}
      >
        <AssociateCourseToUser />
      </Modal>
    )
  }

  openModalAssociateCourseToUser(){
    this.setState({modalAssociateCourseToUser: true, modalCreateUserOpen: false})
  }
  render() {
    return (
      <Dashboard>
        <CardPanel>
          <Row>
            <Col m={3} s={12}>
              <Button
                node="button"
                waves="light"
                className="secondary-content left"
                onClick={() => this.openModalCreateUser()}
              >
                Criar novo usuário
              </Button>
            </Col>
            <Col m={6} s={12}>
              <UserSearch/>
            </Col>

            <Col m={3} s={12}>
              <Button
                node="button"
                waves="light"
                className="secondary-content"
                onClick={() => this.openModalAssociateCourseToUser()}
              >
                Adicionar curso para usuário
              </Button>
            </Col>
            <Col m={12} s={6}>
              <UserList />
            </Col>
          </Row>
        </CardPanel>

        {this.modalCreateUser()}
        {this.modalAssociateCourseToUser()}
        
      </Dashboard>
    )
  }
}
