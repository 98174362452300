import React from "react";
import { Redirect} from 'react-router-dom';

// import Course from '../Course/Course';
import User from '../User/User';

import { Dashboard } from '../Dashboard/Dashboard';

import { isAuthenticated } from "./../util/Athentication";

export default class Home extends React.Component {
  
  render() {
    if (isAuthenticated() === false) {
      return <Redirect to='/login' />;
    }else{

      return (
          <User />
      )
    }

  }
}
