import React, { Component } from 'react'
import axios from 'axios';
import { CardPanel, Container, Divider, Table } from 'react-materialize';
import Moment from 'react-moment';
import 'moment/locale/pt-br';
import { Api, Header} from "./../util/Api";
import { 
  Button,
  Icon,
  Row,
  Col,
  Pagination,
  Dropdown,
  Modal
} from 'react-materialize';
import M from 'materialize-css';

export default class LogList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logs: [],
      user_id: '',
      log: [],
      paginate: [],
      modalLogDetail: false,
      openModalListCourseEachUser: false,
      activePage: 1,
      countPaginate: 0
    };
  }
  componentDidMount(){
    M.AutoInit();
    M.Modal.init()
    M.Tabs.init()
    this.getLogAll();
  }


  modalLogDetail(){
    return (
      <Modal
      actions={[
        <div>
          <Button flat  modal="close" node="button" waves="green" className="right"
            onClick={() => this.setState({modalLogDetail: false})}>
              <Icon>close</Icon>
          </Button>
        </div>
      ]}
      bottomSheet={false}
      fixedFooter={false}
      id="Modal-User"
      open={this.state.modalLogDetail}
      options={{
        dismissible: true,
        endingTop: '20%',
        inDuration: 250,
        onCloseEnd:  () => { this.setState({modalLogDetail: false})} ,
        opacity: 0.5,
        outDuration: 250,
        preventScrolling: true,
        startingTop: '4%'
      }}
      >
        {this.listUserDetail()}
      </Modal>
    )
  }
  getLogAll(){
    axios.get(Api()+ `/admin/log/${this.state.activePage}`, { headers: Header() })
    .then(response => {
      this.setState({
        logs: response.data.results,
        countPaginate:  Math.ceil(response.data.count / 10)
      });
    })
    .catch(error => {
      console.log(error);
    })
  }
  
  
  listLog(){
    const listItems = this.state.logs.map((item) => 
      <tr key={item.pk}>
        <td>{item.pk}</td>
        <td>{item.fields.title}</td>
        <td>{item.fields.user}</td>
        <td>
          <Moment format="LLL" locale="pt-br">
            {item.fields.timestamp}
          </Moment>
        </td>
      </tr>
    );
    return listItems;

  }


  
  render() {
    return (
      <Container>
        <CardPanel>
        <Divider></Divider>
        <Button
          node="button"
          waves="light"
          className="secondary-content"
          onClick={() => this.getLogAll()}
        >
          <Icon>
            autorenew
          </Icon>
        </Button>
        <Table>
          <thead>
              <tr>
                <th data-field="id">
                  ID
                </th>
                <th data-field="username">
                  Descrição
                </th>
                <th data-field="email">
                  Username
                </th>
                <th data-field="data">
                  Data
                </th>
              </tr>
            </thead>
            <tbody>
              {this.listLog()}
            </tbody>
        </Table>

        <Row>
          <Col m={12}>
            <Pagination
              className="center"
              activePage={this.state.activePage}
              items={this.state.countPaginate}
              maxButtons={8}
              leftBtn={<Icon>chevron_left</Icon>}
              rightBtn={<Icon>chevron_right</Icon>}
              onSelect={ async (e)=>  {
                await this.setState({activePage: e});
                console.log(e)
                this.getLogAll(); 
              }}
            />
          </Col>
        </Row>
        </CardPanel>
      </Container>
    )
  }
}
