import React, { Component } from 'react'
import axios from 'axios';
import { Divider, Table } from 'react-materialize';
import Moment from 'react-moment';
import 'moment/locale/pt-br';
import { Api, Header} from "./../util/Api";
import { 
  Button,
  Icon,
  Row,
  Col,
  Pagination,
  Dropdown,
  Modal
} from 'react-materialize';
import M from 'materialize-css';
import './UserList.css';
export default class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      user_id: '',
      user: [],
      course: [],
      paginate: [],
      modalUserDetail: false,
      openModalListCourseEachUser: false,
      activePage: 1,
      countPaginate: 0
    };
  }
  componentDidMount(){
    M.AutoInit();
    M.Modal.init()
    M.Tabs.init()
    this.getUserAll();
  }

  openModalUserDetail(id){
    this.setState({
      modalUserDetail: true
    });
    this.getCourseToUserAll(id);
  }
  openModalRemoveCouseToCourse(){
    this.setState({
      openModalUserDetail: false
    })
  }
 
  modalUserDetail(){
    return (
      <Modal
      actions={[
        <div>
          <Button flat  modal="close" node="button" waves="green" className="right"
            onClick={() => this.setState({modalUserDetail: false})}>
              <Icon>close</Icon>
          </Button>
        </div>
      ]}
      bottomSheet={false}
      fixedFooter={false}
      open={this.state.modalUserDetail}
      options={{
        dismissible: true,
        endingTop: '20%',
        inDuration: 250,
        onCloseEnd:  () => { this.setState({modalUserDetail: false})} ,
        opacity: 0.5,
        outDuration: 250,
        preventScrolling: true,
        startingTop: '4%'
      }}
      >
        {this.listUserDetail()}
      </Modal>
    )
  }
  getUserAll(){
    axios.get(Api()+ `/admin/users/users/all/${this.state.activePage}`, { headers: Header() })
    .then(response => {
      this.setState({
        users: response.data.results,
        countPaginate:  Math.ceil(response.data.count / 10)
      });
    })
    .catch(error => {
      console.log(error);
    })
  }
  getCourseToUserAll(id){
    axios.get(Api()+ `/admin/course_user/${id}`, { headers: Header() })
    .then(response => {
      this.setState({
        course: response.data.course
      });
    })
    .catch(error => {
      console.log(error);
    })
  }
  deleteCourseUserAssociation(user_id, course_id){
    const data = {
      user_id: user_id,
      course_id: course_id
    }
    axios.delete(Api()+ `/admin/delete_course_user_association/${user_id}/${course_id}`, 
    { headers: Header(), data: data })
    .then(response => {
      console.log(response);
      this.setState({
        openModalListCourseEachUser: false
      })
      this.getCourseToUserAll(user_id);
    })
    .catch(error => {
      console.log(error);
    })
  }
  listUser(){
    const listItems = this.state.users.map((item) => 
      <tr key={item.pk}>
        <td>{item.pk}</td>
        <td>{item.fields.username}</td>
        <td>{item.fields.email}</td>
        <td>
          <Moment format="LLL" locale="pt-br">
          {item.fields.date_joined}
          </Moment>
        </td>

        <td>
          <Button
              node="a"
              small
              waves="light"
              onClick={() => {
                this.openModalUserDetail(item.pk)
                this.setState({ 
                  user_id: item.pk,
                  user: item.fields
                });
              }}
            >
              Detalhar
            </Button>
          </td>

      </tr>
    );
    return listItems;

  }

  listUserDetail() {
    return (
      <div>
        <Table>
          <thead>
            <tr>
              <th data-field="id">
                ID
              </th>
              <th data-field="username">
                Username
              </th>
              <th data-field="email">
                Email
              </th>
              <th data-field="data">
                Data
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{this.state.user_id}</td>
              <td>{this.state.user.username}</td>
              <td>{this.state.user.email}</td>
              <td>
                {/* {this.state.user.date_joined} */}
                <Moment format="LLL" locale="pt-br">
                  {this.state.user.date_joined}
                </Moment>

              </td>
            </tr> 
          </tbody>
        </Table>
        <Table>
          <thead>
              <tr>
                <th data-field="code">
                  Código
                </th>
                <th data-field="title">
                Título
                </th>
                <th data-field="action">
                  Desvicular
                </th>
              </tr>
            </thead>
            <tbody>
              {this.listCourseEachUser(this.state.user_id)}
            </tbody>
        </Table>
      </div>
    )
  }

  openCloseModalListCourseEachUser(user_id, course_id){
    this.deleteCourseUserAssociation(user_id, course_id);
    this.setState({openModalListCourseEachUser: true});
  }
  
  listCourseEachUser(user_id){
    const listItemsCourse = this.state.course.map((item) => 
      <tr key={item.pk}>
        <td>{item.pk}</td>
        <td>{item.fields.title}</td>
        <td>
        <Modal
          actions={[
            
          ]}
          bottomSheet={false}
          fixedFooter={false}
          header="Desvincular curso para usuário"
          open={this.state.openModalListCourseEachUser}
          className="UserListModal "
          options={{
            dismissible: true,
            endingTop: '10%',
            inDuration: 250,
            onCloseEnd: null,
            onCloseStart: null,
            onOpenEnd: null,
            onOpenStart: null,
            opacity: 0.5,
            outDuration: 250,
            preventScrolling: true,
            startingTop: '4%'
          }}
            trigger={<Button node="button">Desvicular</Button>}
          >
            <Button node="button" node="button" waves="green" className="green left" 
            onClick={() => this.openCloseModalListCourseEachUser(user_id, item.pk)}>Desvincular</Button>
            <Button modal="close" node="button" waves="red" className="red white-color right">
              Fechar
            </Button>
          </Modal>
        </td>
      </tr>
    );
    return listItemsCourse;
  }
  render() {
    return (
      <div className="user_list">
        <Divider></Divider>
        <Button
          node="button"
          waves="light"
          className="secondary-content"
          onClick={() => this.getUserAll()}
        >
          <Icon>
            autorenew
          </Icon>
        </Button>
        <Table>
          <thead>
              <tr>
                <th data-field="id">
                  ID
                </th>
                <th data-field="username">
                  Username
                </th>
                <th data-field="email">
                  Email
                </th>
                <th data-field="data">
                  Data
                </th>
                <th data-field="detalhe">
                  Detalhe
                </th>
              </tr>
            </thead>
            <tbody>
              {this.listUser()}
            </tbody>
        </Table>
        {this.modalUserDetail()}

        <Row>
          <Col m={12}>
            <Pagination
              className="center"
              activePage={this.state.activePage}
              items={this.state.countPaginate}
              maxButtons={8}
              leftBtn={<Icon>chevron_left</Icon>}
              rightBtn={<Icon>chevron_right</Icon>}
              onSelect={ async (e)=>  {
                await this.setState({activePage: e});
                console.log(e)
                this.getUserAll(); 
              }}
            />
          </Col>
        </Row>
      </div>
    )
  }
}
