import React, { Component } from 'react'
import axios from 'axios';

import { Api, Header} from "./../util/Api";
import {
  Icon,
  Button,
  Row,
  Col,
  Collection,
  CollectionItem,
  CardPanel,
  Select,
  Divider
} from 'react-materialize';
import MaskedInput from 'react-maskedinput'
import './UserCreate.css';
import M from 'materialize-css';
import moment from 'moment';
import 'moment/locale/pt-br';
import SelectSearch from 'react-select';

import Materialize from 'materialize-css';

export default class UserCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      course: '',
      date: '',
      allCourse: [],
      statusHandleCreateUser: 'grey-text',
      statusHandleAssociateCourseToUser: 'grey-text',
      statusUser: '',
      statusUserColor: '',
      loading: false,
    };
    this.handleCreateUser = this.handleCreateUser.bind(this);
    this.handleAssociateCourseToUser = this.handleAssociateCourseToUser.bind(this);
    this.handlerChange = this.handlerChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  
  componentDidMount() {
    this.handleCouserIdNameWorkload();
    M.AutoInit();
  }

  handleCreateUser = () => {
    axios.post(Api()+ `/admin/users/create`, {
      username: this.state.username,
      date: this.state.date
    },
    { headers: Header() })
    .then(response => {
      console.log(response);
      this.setState({statusHandleCreateUser: 'green-text'});
      this.handleAssociateCourseToUser();
    })
    .catch(error => {
      console.log(error.request.status);
      this.setState({
        statusHandleCreateUser: 'red-text',
        statusUserColor: 'red-text',
      });
      if (error.request.status === 500) {
        this.setState({
          statusUser: 'Erro 500!'
        });
      }else{
        this.setState({
          statusUser: error.request.response
        });
      }
    })
  }
  handleAssociateCourseToUser = () => {
    axios.post(Api()+ `/admin/users/associate_course_user`, {
      username: this.state.username,
      course: this.state.course
    },
    { headers: Header() })
    .then(response => {
      this.setState({
        statusHandleAssociateCourseToUser: 'green-text',
        statusUser: 'Usuário criado com sucesso !!!',
        statusUserColor: 'green-text',
        username: '',
        course: '',
        date: ''
      });
    })
    .catch(error => {
      this.setState({
        statusHandleAssociateCourseToUser: 'red-text',
        statusUserColor: 'red-text',
        statusUser: 'Erro em inserir curso para o aluno'
      });
      console.log(error);
    })
  }

  handleCouserIdNameWorkload = () =>{
    axios.get(Api()+ `/admin/course_id_name_workload`, { headers: Header() })
    .then(response => {
      this.setState({
        allCourse: response.data,
      });
    })
    .catch(error => {
      console.log(error);
    })
  }
  
  handlerChange = (event) => {
    let name = event.target.name;
    let val = event.target.value;
    this.setState({[name]: val});

    // console.log(this.state.date)
  }

  handlerChangeSelect = (event) => {
    console.log(`Option selected:`, event.value);

    this.setState({course: event.value});
    console.log(this.state.course)
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      statusHandleCreateUser: 'grey-text',
      statusHandleAssociateCourseToUser: 'grey-text',
      statusHandleSendEmailNewUser: 'grey-text',
      username: event.target.elements.username.value,
      date: event.target.elements.date.value,
      course: event.target.elements.course.value,
      statusUser: ''
    });
    console.log(this.state.date);
    this.handleCreateUser();
    // event.target.reset();
  }
  
  render() {
    return (
      <div>
        <Row>
          <Col m={6} s={6}>
            <h5 className="title card-title">Criar novo usuário</h5>
            <Divider />
            <form onSubmit={this.handleSubmit}>
              <Row>

                <Col m={12} s={12}>
                  <label htmlFor="username">Digite seu username/email</label>
                  <input 
                    type="text" 
                    name="username"
                    placeholder="Digite seu username/email"
                    id="username"
                    value={this.state.username}
                    onChange={this.handlerChange}/>
                </Col>
                <Col m={12} s={12}>
                    <SelectSearch
                      className="select-search"
                      onChange={this.handlerChangeSelect}
                      name="course"
                      id="course"
                      placeholder="Buscar Curso"
                      // value={this.state.course}
                      isSearchable
                      options = {this.state.allCourse.map((item) => {
                        return {
                          value: item.id,
                          label: `${item.id} - ${item.title} - ${item.workload} horas`
                        };
                      })}
                      theme={theme => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary25: '#0000000d',
                          primary: '#fff',
                          neutral0: '#fff',
                        },
                      })}
                    />

                </Col>
                
                <Col m={12} s={12} className="date-field">
                  <label>Selecione sua data de nascimento</label>
                  <MaskedInput 
                    name="date"
                    mask="11/11/1111"
                    onChange={this.handlerChange}
                    value={this.state.date} 
                  />

                </Col> 
                <Col m={12} s={12}>
                  <Button node="button" type="submit" waves="light">
                    Submit
                    <Icon right>
                      send
                    </Icon>
                  </Button>
                </Col>
              </Row>
            </form>
            {this.state.statusUser !== '' ? <p className={this.state.statusUserColor}>{this.state.statusUser}</p>: null }
          </Col>
          <Col m={6} s={6}>
            <CardPanel>
              <Collection header="Status">
                <CollectionItem >
                  Criar usuário
                  <span
                    className="secondary-content"
                  >
                    <Icon className={this.state.statusHandleCreateUser}>
                      check
                    </Icon>
                  </span>
                </CollectionItem>
                <CollectionItem>
                  Associar Curso
                  <span
                    className="secondary-content"
                  >
                    <Icon className={this.state.statusHandleAssociateCourseToUser}>
                      check
                    </Icon>
                  </span>
                </CollectionItem>
              </Collection>
            </CardPanel>
          </Col>
        </Row>
      </div>
    )
  }
}
