import React, { Component } from 'react';
import axios from 'axios';
import { Api, Header} from "./../util/Api";
import {
  Modal,
  Icon,
  Container,
  Collection,
  CollectionItem,
  Button,
  Divider,
  Row,
  Col,
  CardPanel
} from 'react-materialize';
import Menu from '../component/Menu';
import './CourseEachSubject.css';

export default class CourseEachSubject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: this.props.match.params,
      modalOpen: false,
      course: [],
      subjects: [],
      courseTitle: '',
      courseCode: '',
      courseAuthor: '',
      courseField: '',
      coursePrice: 0.00,
      courseStudents: '',
      courseDescription: ''
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeTitle = this.handleChangeTitle.bind(this);
    this.handleChangeCode = this.handleChangeCode.bind(this);
    this.handleChangeAuthor = this.handleChangeAuthor.bind(this);
    this.handleChangeField = this.handleChangeField.bind(this);
    this.handleChangePrice = this.handleChangePrice.bind(this);
    this.handleChangeStudents = this.handleChangeStudents.bind(this);
    this.handleChangeDescription = this.handleChangeDescription.bind(this);
  }
 
  componentDidMount(){
    this.getSubjectApi()
  }
  handleChangeTitle(event) {
    let course = this.state.course;
    course.title = event;

    this.setState({...this.state, course});
  }
  handleChangeCode(event) {
    let course = this.state.course;
    course.code = event;

    this.setState({...this.state, course});
  }
  handleChangeAuthor(event) {
    let course = this.state.course;
    course.author = event;

    this.setState({...this.state, course});
  }
  handleChangeField(event) {
    let course = this.state.course;
    course.field = event;

    this.setState({...this.state, course});
  }
  handleChangePrice(event) {
    let course = this.state.course;
    course.price = event;

    this.setState({...this.state, course});
  }
  handleChangeStudents(event) {
    let course = this.state.course;
    course.students = event;

    this.setState({...this.state, course});
  }
  handleChangeDescription(event) {
    let course = this.state.course;
    course.description = event;

    this.setState({...this.state, course});
  }
  handleSubmit(event){
    event.preventDefault();
    this.updateSubjectApi()
  }
  updateCourse(){
    console.log('test')
    console.log(this.state.course)
  }
  removeSubjectToCourse(){
  }

  openModalSubjectToCourse(){
    this.setState({modalOpen: true})
  }

  modalRemoveSubjectToCourse(){
    return(
      <Modal
        actions={[
          <div>
            <Button  modal="close" node="button" waves="red" className="green left"
              onClick={() => this.setState({modalOpen: false})}>
                Desvincular
            </Button>
            <Button modal="close" node="button" waves="green" className="red right"
              onClick={() => this.setState({modalOpen: false})}>
                Fechar
            </Button>
          </div>

        ]}
        bottomSheet={false}
        fixedFooter={false}
        id="Modal-Course"
        open={this.state.modalOpen}
        options={{
          dismissible: true,
          endingTop: '20%',
          inDuration: 250,
          onCloseEnd:  () => { this.setState({modalOpen: false})} ,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: '4%'
        }}
      >
        <h6>Deseja desvincular a disciplina do curso</h6>
      </Modal>
    )
  }
  getSubjectApi(){
    axios.get(Api()+ `/admin/courses/course/${parseInt(this.state.url.id)}`, { headers: Header() })
    .then(response => {
       this.setState({
        subjects: response.data.subjects,
        course: response.data.course[0].fields
      });
    })
    .catch(error => {
      console.log(error);
    })
  }

  updateSubjectApi(){
    axios.post(Api()+ `/admin/courses/edit-user/${parseInt(this.state.url.id)}`, {
      title: this.state.course.title,
      code: this.state.course.code,
      description: this.state.course.description,
      price: this.state.course.price,
      workload: this.state.course.workload
    },
    { headers: Header() })
    .then(response => {
      console.log(response);

      console.log("works!")
    })
    .catch(error => {
      console.log(error);
    })
  }
  getCourse(){
    return(
      <CardPanel key={this.state.course.pk}>
        <h4>{this.state.course.title}</h4>
        <form onSubmit={this.handleSubmit}>
          <Row>
            <Col m={6} s={12}>
              <div className="input-field col s12">
                <input
                  id="TextInput-Course-00"
                  defaultValue={this.state.course.title}
                  name="title"
                  onChange={(event) => this.handleChangeTitle(event.target.value)}
                />
                <label htmlFor="TextInput-Course-00"  className="active">Título</label>
              </div>
              <div className="input-field col s12">
                <input
                  id="TextInput-Course-01"
                  name="code"
                  defaultValue={this.state.course.code}
                  onChange={(event) => this.handleChangeCode(event.target.value)}
                />
                <label htmlFor="TextInput-Course-01"  className="active">Código</label>
              </div>
              <div className="input-field col s12">
                <input
                  id="TextInput-Course-03"
                  name="author"
                  defaultValue={this.state.course.author}
                  onChange={(event) =>  this.handleChangeAuthor(event.target.value)}
                />
                <label htmlFor="TextInput-Course-03" className="active">Ator</label>
              </div>
            </Col>
            <Col m={6} s={12}>
              <div className="input-field col s12">
                <input
                  id="TextInput-Course-04"
                  defaultValue={this.state.course.field}
                  name="field"
                  onChange={(event) => this.handleChangeField(event.target.value)}

                />
                <label htmlFor="TextInput-Course-04" className="active">Campo</label>
              </div>
              <div className="input-field col s12">
                <input
                  id="TextInput-Course-05"
                  defaultValue={this.state.course.price}
                  name="price"
                  onChange={(event) => this.handleChangePrice(event.target.value)}
                />
                <label htmlFor="TextInput-Course-05" className="active">Preço</label>
              </div>
              <div className="input-field col s12">
                <input
                  id="TextInput-Course-06"
                  defaultValue={this.state.course.students}
                  name="students"
                  onChange={(event) => this.handleChangeStudents(event.target.value)}
                />
                <label htmlFor="TextInput-Course-06" className="active">Estudantes</label>
              </div>
            </Col>
            <Col m={12} s={12}>
              <div className="input-field col s12">
                <textarea
                  id="TextInput-Course-02"
                  className="materialize-textarea"
                  name="description"
                  defaultValue={this.state.description}
                  onChange={(event) => this.handleChangeDescription(event.target.value)}
                >
                </textarea>
                <label htmlFor="TextInput-Course-02" className="active">Descrição</label>
              </div>
            </Col>
            <Col m={12} s={12}>
                <Button
                  node="button"
                  className="right cfisp-background"
                  waves="light"
                >
                  Salvar
                </Button>
            </Col>
          </Row>
        </form>
      </CardPanel>
    )
  }
  getSubjectEachCourse(){
    return (<Collection>
          {this.state.subjects.map((item) => (
            <CollectionItem key={item.pk}>
              {item.fields.title}
                
                <Button
                  flat
                  node="button"
                  waves="light"
                  className="secondary-content btn-collection"
                  onClick={() => this.openModalSubjectToCourse()}
                >
                  <Icon className="cfisp-color">
                    close
                  </Icon>
                </Button>
            </CollectionItem>
          ))}
        </Collection>
    )  
  }

  render() {
    return (
        <div>
          <Menu />
          <Container>
          <Row>
            <Col m={12} s={12}>
              {this.getCourse()}
              <CardPanel>
              <h3 className="title">Disciplinas</h3>
                <Divider />

                {this.getSubjectEachCourse()}
              </CardPanel>
            </Col>
          </Row>
          </Container>
          {this.modalRemoveSubjectToCourse()}
        </div>
    );
  }
}
