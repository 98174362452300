import React, { Component } from 'react'
import { Button } from 'react-materialize';
import { isAuthenticated } from "./../util/Athentication";
import { Redirect} from 'react-router-dom';

export default class Logout extends Component {
  constructor() {
    super();
    this.state = {admin: true}
    this.logout = this.logout.bind(this);
  }

  logout() {
    localStorage.clear();
    this.setState({admin: false})
  }
  render() {
    if (isAuthenticated() === false || this.state.admin === false) {
      return <Redirect to='/login' />;
    }else{
      return (
      <Button node="button" className="white-text grey" waves="light" onClick={() => this.logout()}> logout</Button>
      )
    }
  }
}
