import React from "react";
import { 
  Button,
  CardPanel,
  Toast,
  Row,
  Icon,
  Col } from 'react-materialize';
import axios from 'axios';
import { Redirect} from 'react-router-dom';


import { isAuthenticated } from "./../util/Athentication";
import { Api } from "./../util/Api";
import "./Login.css";


export default class Login extends  React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      error: ""
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }
 
  handleLogin = () => {
    console.log(Api()+ '/login/')
    axios.post(Api() + '/login/', { 
      username:  this.state.username,
      password:  this.state.password 
    }).then(response => {
      if (response.status === 200) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('username', response.data.username);
        this.setState({admin: true});
      }
    }).catch(error => {
      console.log(error.response.status)
      if(error.response.status === 401){
        console.log('401');
        this.setState({error: 'Acesso não autorizado. Você está no grupo "sales"?'});
      } else if(error.response.status === 404){
        this.setState({error: 'Credenciais inválidas.'});
        Toast({html: "Credenciais inválidas"});
      } else{
        console.log('error');
      }
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({username: event.target.elements.username.value});
    this.setState({password: event.target.elements.password.value});
    localStorage.setItem('username', event.target.elements.username.value);
    this.handleLogin();
  }
  handlerChange = (event) => {
    let name = event.target.name;
    let val = event.target.value;
    this.setState({[name]: val});
  }
  
  render(){
    if (this.state.admin === true || isAuthenticated() === true) {
      return <Redirect to='/' />;
    } else {
      return (
        <div className="login-page">

        <Row>
          <Col m={4} s={12} offset={'m4'}>
              <CardPanel className="white">
                <form onSubmit={this.handleSubmit}>
                  <span className="title card-title">Login</span>
                  <input type="text"  name="username" placeholder="Digite seu username" onChange={this.handlerChange}/>
                  <input type="password" name="password" placeholder="Digite sua senha" onChange={this.handlerChange}/>
                  <Button node="button" type="submit" waves="light">
                    Submit
                    <Icon right>
                      send
                    </Icon>
                  </Button>
                  </form>
                <p className="error-login">{this.state.error}</p>
              </CardPanel>
          </Col>
        </Row>
        </div>

      );
    } 
  }
}