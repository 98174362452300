import React, { Component } from 'react';
import './Loader.css';
import ContentLoader from "react-content-loader"

export default class Loader extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="loading">
         <ContentLoader 
            speed={2}
            width={1000}
            height={800}
            viewBox="0 0 1000 800"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect y="0" x="0" rx="5" ry="5" width="1000" height="25" />
            <rect y="50" x="0" rx="5" ry="5" width="1000" height="25" />
            <rect y="100" x="0" rx="5" ry="5" width="1000" height="25" />
            <rect y="150" x="0" rx="5" ry="5" width="1000" height="25" />
            <rect y="200" x="0" rx="5" ry="5" width="1000" height="25" />
            <rect y="250" x="0" rx="5" ry="5" width="1000" height="25" />
            <rect y="300" x="0" rx="5" ry="5" width="1000" height="25" />
            <rect y="350" x="0" rx="5" ry="5" width="1000" height="25" />
            <rect y="400" x="0" rx="5" ry="5" width="1000" height="25" />
            <rect y="450" x="0" rx="5" ry="5" width="1000" height="25" />
          </ContentLoader>
        </div>
    );
  }
}
