
import React, {  } from 'react'
import { Redirect} from 'react-router-dom';
import { 
    Row,
    Col
} from 'react-materialize';

import Menu from '../component/Menu';
import SideNav from '../component/MenuLeft';
import './Dashboard.css';

import { isAuthenticated } from "./../util/Athentication";

export function Dashboard(props){
  if (isAuthenticated() === false) {
    return <Redirect to='/login' />;
  }else{
    return (
      <div>
        <Menu />
        <SideNav />
        <main>
            <Row>
              <Col m={12} s={12}>
              {props.children}
              </Col>
            </Row>
        </main>
      </div>
    );
  }
}

