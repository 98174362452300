import React, { Component } from 'react'
import axios from 'axios';

import { Api, Header} from "./../util/Api";
import {
  Icon,
  Button,
  Row,
  Col,
  Collection,
  CollectionItem,
  CardPanel,
  Table,
  Select,
  Divider
} from 'react-materialize';
import './AssociateCourseToUser.css';

export default class AssociateCourseToUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      allCouseUser: [],
      findCourse: false,
      course: '',
      allCourse: [],
      statusHandleAssociateCourseToUser: 'grey-text',
      statusUserColor: '',
      statusUser: '',
      statusCheck: false,
      emailCheck: false,
      loading: false,
      
    };
    this.handleAssociateCourseToUser = this.handleAssociateCourseToUser.bind(this);
    this.handlerChange = this.handlerChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  
  componentDidMount() {
    this.handleCouserIdNameWorkload();
  }
  
  handleAssociateCourseToUser = () => {
    axios.post(Api()+ `/admin/users/associate_course_user`, {
      username: this.state.username,
      course: this.state.course
    },
    { headers: Header() })
    .then(response => {
      this.setState({
        statusHandleAssociateCourseToUser: 'green-text',
        statusUser: 'Adicionado o curso para usuário',
        statusUserColor: 'green-text',
        statusCheck: true,
        emailCheck: false
      });
      console.log(response);
    })
    .catch(error => {
      if (error.request.status === 400) {
        console.log(error.request.status)
        this.setState({
          statusHandleAssociateCourseToUser: 'red-text',
          statusUser: 'Erro em associar curso para o aluno. Usuário não existe.',
          statusUserColor: 'red-text',
          statusCheck: true,
          emailCheck: false
        });
      }
      else{
        this.setState({
          statusHandleAssociateCourseToUser: 'red-text',
          statusUser: 'Erro em associar curso para o aluno. Usuário não existe',
          statusUserColor: 'red-text'
        });
      }
      console.log(error);
    })
  }

  handleCouserIdNameWorkload = () =>{
    axios.get(Api()+ `/admin/course_id_name_workload`, { headers: Header() })
    .then(response => {
      this.setState({
        allCourse: response.data,
      });
    })
    .catch(error => {
      if (error.request.status === 400) {
        console.log("search")
        this.setState({
          statusHandleAssociateCourseToUser: 'red-text',
          statusUser: 'Erro em associar curso para o aluno. Usuário não existe.',
          statusUserColor: 'red-text',
          statusCheck: true,
          emailCheck: false
        });
      }
      console.log(error);
    })
  }

  handlerChange = (event) => {
    let name = event.target.name;
    let val = event.target.value;
    this.setState({[name]: val});
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.emailCheck === false) {
      this.setState({
        statusHandleAssociateCourseToUser: 'grey-text',
        username: event.target.elements.username.value,
        statusUser: '',
        findCourse: true,
        statusCheck: false
      });
      this.findUserEmail()
    } else {
      this.setState({
        statusHandleAssociateCourseToUser: 'grey-text',
        username: event.target.elements.username.value,
        course: event.target.elements.course.value,
        statusUser: ''
      });
      this.handleAssociateCourseToUser();
    }
  }
  
  selectCourse(){
    const listItems = this.state.allCourse.map((item) => 
        <option
          key={item.id}
          value={item.id}
        >
          {item.id} - {item.title} - {item.workload} horas
        </option>
    );
    return listItems;
  }
  findUserEmail(){
    axios.get(Api()+ `/admin/course_user_with_email/${this.state.username}`, { headers: Header() })
    .then(response => {
      this.setState({
        allCouseUser: response.data.course,
        emailCheck: true,
        findCourse: true
      });
    })
    .catch(error => {
      if (error.request.status === 400) {
        console.log("search")
        this.setState({
          statusHandleAssociateCourseToUser: 'red-text',
          statusUser: 'Erro em associar curso para o aluno. Usuário não existe.',
          statusUserColor: 'red-text',
          statusCheck: true,
          emailCheck: false
        });
      }
      console.log(error);
    })
  }
  listCourseUser(){
    const listItems = this.state.allCouseUser.map((item) => 
      <tr key={item.pk}>
        <td>{item.pk}</td>
        <td>{item.fields.title}</td>
      </tr>
    );
    return listItems;
  }
  selectCourseAll(){
    if(this.state.emailCheck === true){
      return (
        <Select
          label="Escolha seu curso"
          id="course"
          name="course"
          multiple={false}
          m={12}
          s={12}
          onChange={this.handlerChange}
          options={{
            classes: '',
            dropdownOptions: {
              alignment: 'left',
              autoTrigger: true,
              closeOnClick: true,
              constrainWidth: true,
              coverTrigger: true,
              hover: false,
              inDuration: 150,
              onCloseEnd: null,
              onCloseStart: null,
              onOpenEnd: null,
              onOpenStart: null,
              outDuration: 250
            }
          }}
          value=""
        >
          <option
            disabled
            value=""
          >
          </option>
          {this.selectCourse()}
        </Select>
      )
    } 
  }

  statusAssociateCourseToUser(){
    if(this.state.statusCheck === true){
      return (
      <CardPanel>
        <Collection header="Status">
          <CollectionItem>
            Associar Curso
            <span
              className="secondary-content"
            >
              <Icon className={this.state.statusHandleAssociateCourseToUser}>
                check
              </Icon>
            </span>
          </CollectionItem>
        </Collection>
      </CardPanel>
      )
    }else{
      if(this.state.findCourse === true){
        return(
          <div>
            <h3>Esse usuário tem esse curso</h3>
            <Table>
              <thead>
                <tr>
                  <th data-field="code">
                    Código
                  </th>
                  <th data-field="title">
                  Título
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.listCourseUser()}
              </tbody>
          </Table>
          </div>
        )
        }
    }
  }
  render() {
    return (
      <div>
        <Row>
          <Col m={6} s={6}>
            <h5 className="title card-title">Associar curso para o usuário</h5>
            <Divider />
            <form  onSubmit={this.handleSubmit}>
              <Row>

                <Col m={12} s={12}>
                  <label htmlFor="username">Digite seu username/email</label>
                  <input type="text"  name="username" placeholder="Digite seu username/email" id="username" onChange={this.handlerChange}/>

                </Col>
                <Col m={12} s={12}>
                  {this.selectCourseAll()}
                </Col>
                <Col m={12} s={12}>
                {this.state.emailCheck === true ? 
                  <Button node="button" type="submit" waves="light">
                    Submit
                    <Icon right>
                      send
                    </Icon>
                  </Button>
                  :  <Button node="button" waves="light">
                  Buscar email
                  <Icon right>
                    search
                  </Icon>
                </Button>}

                </Col>
              </Row>
            </form>
            {this.state.statusUser !== ''? <p className={this.state.statusUserColor}>{this.state.statusUser}</p>: null }
          </Col>
          <Col m={6} s={6}>

            {this.statusAssociateCourseToUser()}
          </Col>
        </Row>
      </div>
    )
  }
}
