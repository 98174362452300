import React, { Component } from 'react'
import {
  SideNav,
  SideNavItem
} from 'react-materialize';
import cfisp_logo from './cfisp_logo.png'

export default class MenuLeft extends Component {
  render() {
    return (
      <div>
        <SideNav
          id="SideNav-10"
          options={{
            draggable: false
          }}
        >
          <SideNavItem
            user={{
              background: 'https://placeimg.com/640/480/tech',
              email: localStorage.getItem('username'),
              image: cfisp_logo
            }}
            userView
          />
        
        {/* <SideNavItem subheader>
            Curso
          </SideNavItem>
          <SideNavItem
          href="/course"
            waves
          >
            Todos os Cursos
          </SideNavItem> */}
          <SideNavItem divider />
          <SideNavItem subheader>
            Usuários
          </SideNavItem>
          <SideNavItem
            href="/#/user/create_user/"
            waves
          >
            Criar novo Usuário
          </SideNavItem>
        </SideNav>
      </div>
    )
  }
}
